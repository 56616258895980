import React from 'react'
import portretsImage from '../images/portrets/014.jpg'
import stillLifeImage from '../images/stillLife/001.jpg'

export default function PortfolioContent() {
  return <div className = 'portfolio-content'>
    <div className = 'portfolio-content__card'>
      <a href = '/portfolio/portrets'>
        <img src = {portretsImage} alt = '' />
        <div className = 'portfolio-content__card_description'>
          Амбротипы (на стекле)
        </div>
        <div className = 'portfolio-content__card_name'>
          Портреты
        </div>
      </a>
    </div>
    <div className = 'portfolio-content__card'>
      <a href = '/portfolio/still_life'>
        <img src = {stillLifeImage} alt = '' />
        <div className = 'portfolio-content__card_description'>
          Амбротипы (на стекле)
        </div>
        <div className = 'portfolio-content__card_name'>
          Still Life
        </div>
      </a>
    </div>
  </div>
}
